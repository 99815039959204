/*eslint-disable*/
import React, { useEffect, useRef, useState } from "react";
import InputField from "./input/InputField";
import Button from "./Button";
import { useWebSocket } from "../../api/websocket/WebSocketContext";
import { Controller, useForm } from "react-hook-form";
import { ITableLayoutTypes } from "../../utils/types";
import Dropdown from "./dropdown";
import { useLocation } from "react-router-dom";
import { GIFT_CARDS_URL, USER_GIFT_CARD_URL, SEO_OPTIMIZATION_URL, ORDER_MANAGEMENT_URL,SUBSCRIPTION_ORDERS } from "../../constant/url";
import { category_lists, order_lists, variant_lists,subs_order_lists } from "../../api/websocket/payload";
import { LiaFileCsvSolid } from "react-icons/lia";
import { BsFiletypeXlsx } from "react-icons/bs";
import Calender from "./Calender";
import { formatDateTime } from "../../utils/common";
import { RxCross2 } from "react-icons/rx";
import { FaRegUser } from "react-icons/fa";
import { MdOutlineLocalShipping } from "react-icons/md";
import { toast } from "react-toastify";

interface IListTypes {
  children: React.ReactNode;
  handleOpen?: Function;
  title: string;
  payload?: any;
  isAdd?: boolean;
  isFilter?: boolean;
}
const TableLayout: React.FC<IListTypes> = (props) => {
  const { send, receivedMessage } = useWebSocket();
  const { register, handleSubmit, control,watch,setValue } = useForm<ITableLayoutTypes>();
 
  const { isConnect } = useWebSocket();
  const [categoryName, setcategoryName] = useState<any>([]);
  const [variantName, setvariantName] = useState<any>([]);
  const handleKeyDown = (event: any) => {
    if (event.key === "Enter") {
      event.preventDefault();
      // Submit the form programmatically
      event.target.form.requestSubmit();
    }
  };

const SelectedValue =  watch('limit')
  const loc = useLocation();
  let statusOptions: any;

  if (
    loc.pathname === "/order-management/orders" ||
    loc.pathname === "/payment-management" ||
    loc.pathname === "/order-management/subscription" 
  ) {
    statusOptions = [
      { value: "all", label: "All" },
      { value: "paid", label: "Paid" },
      { value: "unpaid", label: "Unpaid" },
    ];
  } else
    statusOptions = [
      { value: "all", label: "All" },
      { value: "active", label: "Active" },
      { value: "inactive", label: "Inactive" },
    ];


  const userLimit: { value: number | string; label: number | string }[] = [
    { value: 10, label: 10 },
    { value: 20, label: 20 },
    { value: 30, label: 30 },
    { value: 40, label: 40 },
    { value: 50, label: 50 },
    { value: 60, label: 60 },
    { value: 70, label: 70 },
    { value: 80, label: 80 },
    { value: 90, label: 90 },
    { value: 100, label: 100 },
  ];
  

  if (loc.pathname === ORDER_MANAGEMENT_URL || loc?.pathname === SUBSCRIPTION_ORDERS) {
    userLimit.push({ value: 500, label: 500 })
    userLimit.push({ value: "others", label: "Others" });
  }
  
  useEffect(() => {
    if (receivedMessage?.url === "category_listing") {
     if(receivedMessage?.response?.status===200){
      if (receivedMessage?.response?.data?.data?.length !== 0) {
        const extractedNames = receivedMessage?.response?.data?.data?.map(
          (item: any) => ({
            label: item.name,
            value: item.id,
          })
        );

        setcategoryName([{ value: "", label: "All" },...extractedNames]);
      }
     }
     if(receivedMessage?.response?.status===400){
      setcategoryName([]);
     }
    }
    if (receivedMessage?.url === "variant_listing") {
      if (receivedMessage?.response?.data?.data?.length !== 0 && Object.keys(receivedMessage?.response?.data)?.length > 0) {
        const extractedNames = receivedMessage?.response?.data?.data
        ?.filter((elm: { is_active: boolean; }) => elm?.is_active === true)
        .sort((a: { quantity: number; }, b: { quantity: number; }) => b.quantity - a.quantity)
        .map((item: any) => ({ 
          label: item.name + ` - (${item?.quantity})  $${(Number(item?.price) / 100).toFixed(2)}`, 
          value: item.id 
        }));
        setvariantName([{ value: "", label: "All" },...extractedNames]);
      } else{
        setvariantName([]);
      }
    }
  }, [receivedMessage]);

  useEffect(() => {
    if (isConnect&&loc.pathname==='/product-management/product') {
      const message1 = category_lists;
      send(message1);
    }
  }, [isConnect]);

  useEffect(() => {
    if (isConnect&&loc.pathname==='/product-management/product') {
      const message1 = variant_lists;
      send(message1);
    }
  }, [isConnect]);

  function onsubmit(data: ITableLayoutTypes) {
    // Create a copy of the current payload and update it
    const updatedPayload = { ...props.payload };
    updatedPayload.request.search = data.search;
    updatedPayload.request.filter = data.filter;
    updatedPayload.request.limit = data.limit;
    if(data?.start_date)
    updatedPayload.request.start_date = formatDateTime(data.start_date);
    if(data?.end_date)
    updatedPayload.request.end_date = formatDateTime(data.end_date);
    updatedPayload.request.page = "1";
    if ('category_id' in data) updatedPayload.request.category_id = data.category_id;
    if ('variant_id' in data) updatedPayload.request.variant_id = data.variant_id;
    send(updatedPayload);
  }

  const handleSelect = handleSubmit(onsubmit);
  const [showTooltip, setShowTooltip] = useState(false);
  const [showTooltip2, setShowTooltip2] = useState(false);
  const [loading,setLoading] = useState(false);
  const [selectedButton, setSelectedButton] = useState<string | null>(null); // Track selected button


  const callDownloadAttachment=(type:string,export_for:string)=>{
    setSelectedButton(export_for)
    setLoading(true)
    if(loc.pathname === ORDER_MANAGEMENT_URL){
      order_lists.request.is_export=true;
      order_lists.request.file_type = type;
      order_lists.request.export_for = export_for;
      send(order_lists)   
    }
    if(loc?.pathname === SUBSCRIPTION_ORDERS){
      subs_order_lists.request.is_export=true;
      subs_order_lists.request.file_type = type;
      subs_order_lists.request.export_for = export_for;
      send(subs_order_lists)  
    }
  }

const startDate=watch('start_date')
const endDate=watch('end_date')
  useEffect(()=>{
    handleSubmit(onsubmit)();
  },[startDate,endDate])

  function callDateChange(e:any){


  }


  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (!isNaN(Number(value)) && Number(value) >= 0) { 
      // setValue('limit',Number(value));
      // handleSubmit(onsubmit)();
      const updatedPayload = { ...props.payload };
      updatedPayload.request.limit = value;
      send(updatedPayload);

    } 
  };


  const removeLimit = () =>{
    setValue('limit',10);
    handleSubmit(onsubmit)();

  }

  const tooltipRef = useRef<HTMLDivElement | null>(null);

  const handleOutsideClick = (event: MouseEvent) => {
      if (tooltipRef.current && !tooltipRef.current.contains(event.target as Node)) {
          setShowTooltip(false);
          setShowTooltip2(false);
      }
  };

  useEffect(() => {
      if (showTooltip||showTooltip2) {
          document.addEventListener("mousedown", handleOutsideClick);
      } else {
          document.removeEventListener("mousedown", handleOutsideClick);
      }
      return () => {
          document.removeEventListener("mousedown", handleOutsideClick);
      };
  }, [showTooltip,showTooltip2]);

  const RendroExportDesign = ({onClick,type,}: { onClick: (type: string, userType: string) => void; type: string;}) => {
    return (
        <div 
            ref={tooltipRef}
            className="absolute left-1/2 transform -translate-x-1/2 mt-2 w-max bg-white text-black text-sm rounded-md shadow-2xl p-4 h-[95px]">
            <div className="flex w-[130px] gap-4 justify-start flex-col">
                <div 
                 className={`flex w-full gap-3 p-[3px] rounded-[5px] justify-start flex-row items-center cursor-pointer ${
                 selectedButton === "pirateship" && loading ? "border-2 border-blue-500" : ""}`}
                 onClick={() => onClick(type, "pirateship")}>
                 <MdOutlineLocalShipping />
                 <p>Pirate Ship</p>
                </div>
                <div 
                 className={`flex w-full gap-3 p-[3px] rounded-[5px] justify-start flex-row items-center cursor-pointer ${
                 selectedButton === "admin" && loading ? "border border-blue-500" : ""
                 }`}
                onClick={() => onClick(type, "admin")}>
                <FaRegUser />
                <p>Admin</p>
                </div>
            </div>
            <div className="absolute -top-[8px] left-1/2 transform -translate-x-1/2 w-4 h-4 bg-white rotate-45" />
        </div>
    );
};

useEffect(() => {
  if (isConnect) {
    if (receivedMessage) {
      if (
        (receivedMessage?.url === order_lists.url ||receivedMessage?.url === subs_order_lists.url) &&
        receivedMessage?.request?.is_export
      ) {
        if (receivedMessage?.response?.status === 200&&receivedMessage?.request?.file_type==='csv') {
          setLoading(false)
          setShowTooltip(false);
          setShowTooltip2(false);
          // toast.success(receivedMessage.response.msg)
        }else{
          setLoading(false)
          if(receivedMessage?.response?.status === 400){
            toast.error(receivedMessage.response.msg);
          }
        }
        if (receivedMessage?.response?.status === 200&&receivedMessage?.request?.file_type==='xlsx') {
          setLoading(false)
          setShowTooltip(false);
          setShowTooltip2(false);
          // toast.success(receivedMessage.response.msg)
        }else{
          setLoading(false)
          if(receivedMessage?.response?.status === 400){
            toast.error(receivedMessage.response.msg);
          }
        }
      }
    }
  }
}, [receivedMessage]);


  return (
    <div className="w-full h-full  bg-white rounded-md overflow-hidden">
      {/* <div className="flex border-b">
        <p className={`px-6 py-2 cursor-pointer `}>{props.title}</p>
      </div> */}
      <form
        action=""
        onSubmit={(e) => {
          handleSubmit(onsubmit)(e);
        }}
      >
        <div className="lg:flex my-2 items-center justify-between mx-3 ">
          {loc.pathname !== GIFT_CARDS_URL &&
            loc.pathname !== USER_GIFT_CARD_URL &&
            loc.pathname !== SEO_OPTIMIZATION_URL && (
              <InputField
                name={""}
                register={register("search")}
                placeholder={`Search by ${props.title} & press enter`}
                useFor="search"
                inputClassName="h-8 lg:w-72 w-full"
                className="h-10 mt-2"
                onEnterClick={handleKeyDown}
              />
            )}

          <div className="w-full">
            <div className="flex justify-between lg:justify-end  gap-2">
              {loc.pathname === "/product-management/product" && (
                <>
                  {" "}
                  <Controller
                    name="category_id"
                    control={control}
                    render={({ field }) => (
                      <Dropdown
                        label=""
                        options={categoryName}
                        value={field.value}
                        onChange={(e: any) => {
                          field.onChange(e);
                          handleSelect(e); // Use the value from the event
                        }}
                        name={field.name}
                        // onSelect={handleSelect}
                        className="py-2"
                      />
                    )}
                  />
                  <Controller
                    name="variant_id"
                    control={control}
                    // defaultValue="all"
                    render={({ field }) => (
                      <Dropdown
                        label=""
                        options={variantName}
                        value={field.value}
                        onChange={(e: any) => {
                          field.onChange(e);
                          handleSelect(e); // Use the value from the event
                        }}
                        name={field.name}
                        // onSelect={handleSelect}
                        className="py-2"
                      />
                    )}
                  />
                </>
              )}
               {(loc.pathname === ORDER_MANAGEMENT_URL || loc?.pathname===SUBSCRIPTION_ORDERS) && (
                <div className="mt-[0.125rem] flex gap-2">
                <Calender name="start_date" placeholder="Start Date" control={control} showIcon onChange={(e:any)=>callDateChange(e)} maxDate={endDate}/>
                <Calender name="end_date" placeholder="End Date" control={control} showIcon onChange={onsubmit} minDate={startDate}/>
                <div className="relative inline-block">
                <Button label="CSV" className="bg-primary h-[36px]" icon={LiaFileCsvSolid} 
                // onCLick={()=>callDownloadAttachment('csv')}
                onCLick={() =>{setShowTooltip((prev) => !prev);setShowTooltip2(false)}} type="button"/>
                {showTooltip && <RendroExportDesign  type="csv" onClick={callDownloadAttachment}/>}
                </div>
                <div className="relative inline-block">
                <Button label="XLSX" className="bg-primary h-[36px]" icon={BsFiletypeXlsx} 
                // onCLick={()=>callDownloadAttachment('xlsx')}
                onCLick={() =>{setShowTooltip2((prev) => !prev); setShowTooltip(false)}} type="button"/>
                  {showTooltip2 && <RendroExportDesign type="xlsx" onClick={callDownloadAttachment}/>}
                </div>
                </div>
                
              )}

              {props.isFilter && loc.pathname !== USER_GIFT_CARD_URL && (
                <Controller
                  name="filter"
                  control={control}
                  defaultValue="all"
                  render={({ field }) => (
                    <Dropdown
                      label=""
                      options={statusOptions}
                      value={field.value}
                      onChange={(e: any) => {
                        field.onChange(e);
                        handleSelect(e); // Use the value from the event
                      }}
                      name={field.name}
                      // onSelect={handleSelect}
                      className="py-2"
                    />
                  )}
                />
              )}
              <Controller
                name="limit"
                control={control}
                defaultValue={10}
                render={({ field }) => (
                  <>
                  {SelectedValue!=="others" ? (
                    <Dropdown
                      label=""
                      options={userLimit}
                      value={field.value}
                      onChange={(e: any) => {
                        field.onChange(e);
                        handleSelect(e);
                      }}
                      name={field.name}
                      className="py-2"
                    />
                  ) : (
                    <div className="relative">
                        <i
                        onClick={removeLimit}
                        className="absolute right-1 text-red-500 cursor-pointer"
                      >
                        <RxCross2 />
                      </i>
                      <input
                        type="number"
                        className="py-2 px-2 border rounded w-[5rem] outline-none"
                        placeholder="Limit"
                        maxLength={6}
                        onChange={handleInputChange}
            

                      />

                    </div>
                  )}
                </>
        
                )}
              />
              {props.isAdd && (
                <Button
                  onCLick={props.handleOpen}
                  label={`+ Add ${props.title}`}
                  className="bg-[#2737FF] "
                />
              )}
            </div>
          </div>
        </div>
      </form>

      {props.children}
    </div>
  );
};

export default TableLayout;
