import {
  DashboardData,
  IAboutusRes,
  ICartDetail,
  ICategoryRes,
  ICateringDetails,
  ICommunityEventRes,
  ICouponRes,
  IDeviceInfo,
  IFeedbackRes,
  IFooterSetting,
  IFundRaiseDetail,
  IGiftCardRes,
  IKitRes,
  IOrderRes,
  IPagesRes,
  IPaymentDetail,
  IProductDetailTypes,
  IProductRes,
  IProductSubscriptionRes,
  IProfiledata,
  IRetailStoreRes,
  IReviewRes,
  ISeoList,
  ISubscriptionMailRes,
  IUserDetail,
  IUserGiftCardRes,
  IUserRes,
  IVariantRes,
} from "../../app/utils/types";

export const ActionTypes = {
  IS_LOGIN: "IS_LOGIN",
  LOADER_SHOW: "LOADER_SHOW",
  SOCKETCONNECT: "SOCKETCONNECT",
  GET_PROFILE_USER: "GET_PROFILE_USER",
  USER_ID: "USER_ID",
  ACCESS_TOKEN: "ACCESS_TOKEN",
  DEVICE_INFO: "DEVICE_INFO",
  CATEGORY_LIST: "CATEGORY_LIST",
  VARIANT_LIST: "VARIANT_LIST",
  ALL_VARIANT_PRODUCTS_LIST: "ALL_VARIANT_PRODUCTS_LIST",
  ABOUT_US_LIST: "ABOUT_US_LIST",
  PAGES_LIST: "PAGES_LIST",
  PRODUCT_LIST: "PRODUCT_LIST",
  ALL_PRODUCT_LIST: "ALL_PRODUCT_LIST",
  PRODUCT_DETAIL: "PRODUCT_DETAIL",
  KIT_LIST: "KIT_LIST",
  RETAIL_STORE_LIST: "RETAIL_STORE_LIST",
  FEEDBACK_LIST: "FEEDBACK_LIST",
  SUBSCRIPTION_EMAIL_LIST: "SUBSCRIPTION_EMAIL_LIST",
  PRODUCT_SUBSCRIPTION_LIST: "PRODUCT_SUBSCRIPTION_LIST",
  COMMUNITY_EVENT_LIST: "COMMUNITY_EVENT_LIST",
  REVIEW_LIST: "REVIEW_LIST",
  SEO_LIST:"SEO_LIST",
  CATERING_LIST: "CATERING_LIST",
  FUND_RAISE_LIST: "FUND_RAISE_LIST",
  ORDER_LIST: "ORDER_LIST",
  SUBSCRIPTION_LIST: "SUBSCRIPTION_LIST",
  ORDER_DETAIL: "ORDER_DETAIL",
  PAYMENT_LIST: "PAYMENT_LIST",
  COUPON_LIST: "COUPON_LIST",
  FOOTER_SETTING: "FOOTER_SETTING",
  FOOTER_SETTING_FOR_SHIPPING_DATE: "FOOTER_SETTING_FOR_SHIPPING_DATE",
  USER_LIST: "USER_LIST",
  USER_DETAIL: "USER_DETAIL",
  GIFTCARD_LIST: "GIFTCARD_LIST",
  DASHBOARD_DETAIL: "DASHBOARD_DETAIL",
  USERGIFT_CARD_LIST:   "USERGIFT_CARD_LIST",
  ADMIN_LOGOUT: "ADMIN_LOGOUT",
};

export interface Action<T = any> {
  type: string;
  payload: T;
}

//ui reducer
export const setLoader = (payload: boolean) => ({
  type: ActionTypes.LOADER_SHOW,
  payload,
});
export const setSocketStatus = (payload: boolean) => ({
  type: ActionTypes.SOCKETCONNECT,
  payload,
});

//data Reducer
export const setIslogin = (payload: string) => ({
  type: ActionTypes.IS_LOGIN,
  payload,
});

export const setDashboard = (payload: DashboardData) => ({
  type: ActionTypes.DASHBOARD_DETAIL,
  payload,
});

export const setProfile = (payload: IProfiledata) => ({
  type: ActionTypes.GET_PROFILE_USER,
  payload,
});

export const setToken = (payload: string) => ({
  type: ActionTypes.ACCESS_TOKEN,
  payload,
});
export const setUserId = (payload: string) => ({
  type: ActionTypes.USER_ID,
  payload,
});

export const setDeviceInfo = (payload: IDeviceInfo) => ({
  type: ActionTypes.DEVICE_INFO,
  payload,
});

export const setCategorylist = (payload: ICategoryRes) => ({
  type: ActionTypes.CATEGORY_LIST,
  payload,
});

export const setCouponlist = (payload: ICouponRes) => ({
  type: ActionTypes.COUPON_LIST,
  payload,
});

export const setUserGiftcardlist = (payload: IUserGiftCardRes) => ({
  type: ActionTypes.USERGIFT_CARD_LIST,
  payload,
});

export const setVariantlist = (payload: IVariantRes) => ({
  type: ActionTypes.VARIANT_LIST,
  payload,
});

export const setVariantAlllist = (payload: IVariantRes) => ({
  type: ActionTypes.ALL_VARIANT_PRODUCTS_LIST,
  payload,
});

export const setAboutuslist = (payload: IAboutusRes) => ({
  type: ActionTypes.ABOUT_US_LIST,
  payload,
});

export const setPageslist = (payload: IPagesRes) => ({
  type: ActionTypes.PAGES_LIST,
  payload,
});

export const setProductlist = (payload: IProductRes) => ({
  type: ActionTypes.PRODUCT_LIST,
  payload,
});

export const setAllProductlist = (payload: IProductRes) => ({
  type: ActionTypes.ALL_PRODUCT_LIST,
  payload,
});

export const setProductdetail = (payload: IProductDetailTypes) => ({
  type: ActionTypes.PRODUCT_DETAIL,
  payload,
});

export const setKitlist = (payload: IKitRes) => ({
  type: ActionTypes.KIT_LIST,
  payload,
});

export const setRetailStorelist = (payload: IRetailStoreRes) => ({
  type: ActionTypes.RETAIL_STORE_LIST,
  payload,
});

export const clearRedux = () => {
  return {
    type: ActionTypes.ADMIN_LOGOUT,
  };
};

export const setFeedbacklist = (payload: IFeedbackRes) => ({
  type: ActionTypes.FEEDBACK_LIST,
  payload,
});

export const setSubscriptionEmaillist = (payload: ISubscriptionMailRes) => ({
  type: ActionTypes.SUBSCRIPTION_EMAIL_LIST,
  payload,
});

export const setProductSubscriptionlist = (
  payload: IProductSubscriptionRes
) => ({
  type: ActionTypes.PRODUCT_SUBSCRIPTION_LIST,
  payload,
});

export const setCommunityEventlist = (payload: ICommunityEventRes) => ({
  type: ActionTypes.COMMUNITY_EVENT_LIST,
  payload,
});

export const setUserList = (payload: IUserRes) => ({
  type: ActionTypes.USER_LIST,
  payload,
});

export const setUserDetail = (payload: IUserDetail) => ({
  type: ActionTypes.USER_DETAIL,
  payload,
});

export const setReviewlist = (payload: IReviewRes) => ({
  type: ActionTypes.REVIEW_LIST,
  payload,
});

export const setSeolist = (payload: ISeoList) => ({
  type: ActionTypes.SEO_LIST,
  payload,
});

export const setFooter = (payload: IFooterSetting) => ({
  type: ActionTypes.FOOTER_SETTING,
  payload,
});
export const setFooterShippingDates = (payload: IFooterSetting) => ({
  type: ActionTypes.FOOTER_SETTING_FOR_SHIPPING_DATE,
  payload,
});

export const setCateringlist = (payload: ICateringDetails) => ({
  type: ActionTypes.CATERING_LIST,
  payload,
});

export const setFundraiselist = (payload: IFundRaiseDetail) => ({
  type: ActionTypes.FUND_RAISE_LIST,
  payload,
});

export const setOrderlist = (payload: IOrderRes) => ({
  type: ActionTypes.ORDER_LIST,
  payload,
});
export const setSubScriptionlist = (payload: IOrderRes) => ({
  type: ActionTypes.SUBSCRIPTION_LIST,
  payload,
});

export const setOrderDetail = (payload: ICartDetail) => ({
  type: ActionTypes.ORDER_DETAIL,
  payload,
});

export const setPaymentList = (payload: IPaymentDetail) => ({
  type: ActionTypes.PAYMENT_LIST,
  payload,
});

export const setGiftcardList = (payload: IGiftCardRes) => ({
  type: ActionTypes.GIFTCARD_LIST,
  payload,
});

export const setLogout = () => ({
  type: ActionTypes.ADMIN_LOGOUT,
});
