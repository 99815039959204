/* eslint-disable */
import { useEffect, useState } from "react";
import DashBoardCard from "../../components/DashBoardCard";
import { getData } from "../../../api/rest/fetchData";
import { usePosterReducers } from "../../../../redux/getdata/usePostReducer";
import { useDispatch } from "react-redux";
import { setDashboard } from "../../../../redux/actions/action";
import Chart from "react-google-charts";

const Dashboard = () => {
  const { accessToken, dashboard_data } = usePosterReducers();
  const [PaymentFilter, setPaymentFilter] = useState('week')
  const [OrderFilter, setOrderFilter] = useState('week')
  const dispatch = useDispatch();
  useEffect(() => {
    getData("/admin/dashboard", accessToken).then((data) => {
      dispatch(setDashboard(data?.data?.data));
    });
  }, [accessToken]);
  function changePaymentFilter(filter:string){
    setPaymentFilter(filter)
  }

  function changeOrderFilter(filter:string){
    setOrderFilter(filter)
  }

  const weekPayment = [
    ["Task", "Amount ($)"],
    ...(Object.entries(dashboard_data?.past_week_payment_revenue ?? {}) ?? {}),
  ];

  const yearPayment = [
    ["Task", "Amount ($)"],
    ...(Object.entries(dashboard_data?.past_year_payment_revenue ?? {}) ?? {}),
  ];

  const YearOrder = [
    ["Task", "Orders"],
    ...(Object.entries(dashboard_data?.past_year_order_revenue ?? {}) ?? {}),
  ];

  const weekOrder = [
    ["Task", "Orders"],
    ...(Object.entries(dashboard_data?.past_week_order_revenue ?? {}) ?? {}),
  ];
  const options = {
    curveType: "function",
    colors: PaymentFilter==='week'?["#FFD700"]:["#2737FF"],
    bar: { groupWidth: "90%" },
    legend: { position: "none" },
    is3D: true,
    hAxis: {
      title: "", // No hAxis title
      slantedText: true, // Rotate the labels
      slantedTextAngle: 45, // Rotate by 45 degrees
    },
  };

  const options2 = {
    curveType: "function",
    colors: OrderFilter==='week'?["#FFD700"]:["#2737FF"],
    bar: { groupWidth: "90%" },
    legend: { position: "none" },
    is3D: true,
    hAxis: {
      title: "", // No hAxis title
      slantedText: true, // Rotate the labels
      slantedTextAngle: 45, // Rotate by 45 degrees
    },
  };

  const callNoData = () => {
    return (
      <div className="flex items-center justify-center h-72 bg-white w-full">
        <p className="text-gray-400 text-center">No Data Available</p>
      </div>
    );
  }
  return (
    <div id="dashboard-page" className="h-full p-4 overflow-x-auto">
      <div className="lg:flex gap-1 ">
        <DashBoardCard
          link="/user-management"
          title="Users"
          data={dashboard_data?.users_count}
        />
        <DashBoardCard
          title="Orders"
          // link="/order-management"
          link="/order-management/orders"
          data={dashboard_data?.orders_count}
        />
        <DashBoardCard
          link="/payment-management"
          title="Payments"
          data={{
            total: dashboard_data?.payments_amount?.total,
            count_past_month:
              "$" +
              Number(dashboard_data?.payments_amount?.count_past_month) / 100,
            count_today:
              "$" + Number(dashboard_data?.payments_amount?.count_today) / 100,
            count_past_week:
              "$" +
              Number(dashboard_data?.payments_amount?.count_past_week) / 100,
          }}
        />
      </div>
      <div className="lg:flex text-sm w-full my-4 p-4 gap-4 rounded-md bg-secondary">
        <div className="w-full lg:my-0 my-4  ">
          <div className="flex justify-between items-center py-1"> 
            <h1 className="font-semibold text-gray-500 ">
              {PaymentFilter==='week'?'Weekly':'Yearly'} Payment Revenue
            </h1>
            <div className="flex rounded-md  border cursor-pointer">
              <p onClick={()=>changePaymentFilter('week')} className={`${PaymentFilter==='week'?'bg-primary text-white':''}   p-1 rounded-bl-md rounded-tl-md  px-2`}>Week</p>
              <p onClick={()=>changePaymentFilter('year')} className={`${PaymentFilter==='year'?'bg-primary text-white':''}  p-1 rounded-br-md rounded-tr-md  px-2`}>Year</p>
            </div>
          </div>
          {(weekPayment?.length>1 || yearPayment?.length>1 ) ? (
            <Chart
              chartType="LineChart"
              width="100%"
              height="400px"
              data={PaymentFilter==='week'?weekPayment:yearPayment}
              options={options}
            />
          ):callNoData()}
        </div>
        <div className="w-full lg:my-0 my-4 ">
        <div className="flex justify-between items-center py-1"> 
            <h1 className="font-semibold text-gray-500 ">
              {OrderFilter==='week'?'Weekly':'Yearly'} Order Count
            </h1>
            <div className="flex rounded-md  border cursor-pointer">
              <p onClick={()=>changeOrderFilter('week')} className={`${OrderFilter==='week'?'bg-primary text-white':''}   p-1 rounded-bl-md rounded-tl-md  px-2`}>Week</p>
              <p onClick={()=>changeOrderFilter('year')} className={`${OrderFilter==='year'?'bg-primary text-white':''}  p-1 rounded-br-md rounded-tr-md  px-2`}>Year</p>
            </div>
          </div>
          {(weekOrder?.length>1 || YearOrder?.length>1)? (
            <Chart
              chartType="LineChart"
              width="100%"
              height="400px"
              data={OrderFilter==='week'?weekOrder:YearOrder}
              options={options2}
            />
          ):callNoData()}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
