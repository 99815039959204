/* eslint-disable */
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useWebSocket } from "../../../api/websocket/WebSocketContext";
import TableLayout from "../../components/TableLayout";
import { edit_order_status, subs_order_lists } from "../../../api/websocket/payload";
import { setSubScriptionlist } from "../../../../redux/actions/action";
import { toast } from "react-toastify";
import { initialPage } from "../../../utils/staticdata";
import { dateFormat, downloadCSV, downloadXLSX } from "../../../utils/common";
import List from "./list";

const SubscriptionManagement = () => {
  const { send, receivedMessage, isConnect } = useWebSocket();
  const dispatch = useDispatch();
  useEffect(() => {
    if (isConnect) {
      if (receivedMessage) {
        if (
          receivedMessage?.url === subs_order_lists.url &&
          receivedMessage?.request?.is_export === false
        ) {
          if (receivedMessage?.response?.status === 200)
            dispatch(setSubScriptionlist(receivedMessage?.response?.data));
          if (receivedMessage?.response?.status === 400)
            dispatch(setSubScriptionlist(initialPage));
        }
        if (
          receivedMessage?.url === subs_order_lists.url &&
          receivedMessage?.request?.is_export
        ) {
          const name = `Order-list-${dateFormat(new Date().toString())}`;
          if (
            receivedMessage?.response?.status === 200 &&
            receivedMessage?.request?.file_type === "csv"
          ) {
            downloadCSV(receivedMessage?.response?.data?.data, name);
          }
          if (
            receivedMessage?.response?.status === 200 &&
            receivedMessage?.request?.file_type === "xlsx"
          ) {
            downloadXLSX(receivedMessage?.response?.data?.data, name);
          }
          subs_order_lists.request.is_export = false;
          subs_order_lists.request.file_type = "";
        }

        if (receivedMessage?.url === edit_order_status.url) {
          if (receivedMessage?.response?.status === 200) {
            send(subs_order_lists);
            toast.success("Order status updated successfully");
          }
        }
      }
    }
  }, [receivedMessage]);

  useEffect(() => {
    if (isConnect) {
        const message = subs_order_lists;
      send(message);
    }
  }, [isConnect]);

  useEffect(() => {
    return () => {
      subs_order_lists.request = {
        filter: "all",
        limit: 10,
        page: 1,
        search: "",
        file_type: "",
        export_for:"",
        is_export: false,
        subscription_orders: "subscription-orders",
      };
    };
  }, []);

  return (
    <TableLayout
      isFilter
      isAdd={false}
      title={"Order List"}
      payload={subs_order_lists}
    >
      <List />
    </TableLayout>
  );
};

export default SubscriptionManagement;
