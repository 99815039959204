export const LOGIN_URL='/login'
export const DASHBOARD_URL='/dashboard'
export const PRODUCT_MANAGEMENT_MAIN_URL='/product-management'
export const CATAGORY_MANAGEMENT_URL='/product-management/category'
export const PRODUCT_MANAGEMENT_URL='/product-management/product'
export const PRODUCT_DETAIL_URL='/product-management/product/:id'
export const PRODUCT_EDIT_URL='/product-management/product/edit-product/:id'
export const PRODUCT_ADD_URL='/product-management/product/add-product'
export const VARIANT_MANAGEMENT_URL='/product-management/variant'
export const KIT_MANAGEMENT_URL='/product-management/kit'
export const PRODUCT_SUBSCRIPTION_URL='/product-management/product-subscription'
export const USER_MANAGEMENT_URL='/user-management'
export const USER_DETAIL_URL='/user-management/:id'
export const PAYMENT_MANAGEMENT_URL='/payment-management'
export const COUPON_MANAGEMENT_URL='/coupon-management'
export const ORDER_MANAGEMENT_URL_MAIN='/order-management'
export const ORDER_MANAGEMENT_URL='/order-management/orders'
export const SUBSCRIPTION_ORDERS='/order-management/subscription'
export const ORDER_MANAGEMENT_DETAIL_URL='/order-management/:id'
export const CONTAINT_MANAGEMENT_URL='/content-management'
export const SEO_OPTIMIZATION_URL='/content-management/seo-optimization'
export const ADD_SEO_OPTIMIZATION_URL='/content-management/seo-optimization/add-seo';
export const EDIT_SEO_OPTIMIZATION_URL='/content-management/seo-optimization/edit-seo/:id';
export const FUND_RAISING_URL='/content-management/fund-raising'
export const FUND_RAISING_DETAIL_URL='/content-management/fund-raising/:id'
export const GIFT_CARD_URL='/gift-card'
export const GIFT_CARDS_URL='/gift-card/gift-cards'
export const USER_GIFT_CARD_URL='/gift-card/user-gift-card'
export const FEEDBACK_URL='/content-management/feedback'
export const CATERING_URL='/content-management/catering'
export const CATERING_VIEW_URL='/content-management/catering/:id'
export const ABOUT_US_URL='/content-management/about-us'
export const ADD_ABOUT_US_URL='/content-management/about-us/add-aboutus'
export const EDIT_ABOUT_US_URL='/content-management/about-us/edit-aboutus/:id'
export const ABOUT_US_DETAIL_URL='/content-management/about-us/:id'
export const INFO_PAGES_URL='/content-management/pages'
export const ADD_INFO_PAGES_URL='/content-management/pages/add-page'
export const UPDATE_INFO_PAGES_URL='/content-management/pages/update-page/:id'
export const RETAIL_STORE_URL='/content-management/retail-store'
export const SUBSCRIPTION_MAIL_URL='/content-management/subscription-email'
export const COMMUNITY_EVENT_URL='/content-management/community-event'
// export const REVIEW_URL='/content-management/review'
// export const REVIEW_DETAIL_URL='/content-management/review'
export const SETTING_URL='/setting'
export const FOOTER_URL='/setting/footer-info'
export const FOOTER_URL_ORDERS='/setting/shipping-date-validation'


//endpoints
export const APP_LOGIN_URL='/admin/login'
export const FILE_UPLOAD_URL='/file/upload'
export const LOG_OUT='/user/logout'